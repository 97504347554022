exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archive-blog-index-tsx": () => import("./../../../src/pages/archive/blog/index.tsx" /* webpackChunkName: "component---src-pages-archive-blog-index-tsx" */),
  "component---src-pages-archive-dsa-problems-index-tsx": () => import("./../../../src/pages/archive/dsa-problems/index.tsx" /* webpackChunkName: "component---src-pages-archive-dsa-problems-index-tsx" */),
  "component---src-pages-archive-zkp-index-tsx": () => import("./../../../src/pages/archive/zkp/index.tsx" /* webpackChunkName: "component---src-pages-archive-zkp-index-tsx" */),
  "component---src-pages-cv-index-tsx": () => import("./../../../src/pages/cv/index.tsx" /* webpackChunkName: "component---src-pages-cv-index-tsx" */),
  "component---src-pages-essays-index-tsx": () => import("./../../../src/pages/essays/index.tsx" /* webpackChunkName: "component---src-pages-essays-index-tsx" */),
  "component---src-pages-essays-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/essays/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-essays-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-showcase-index-tsx": () => import("./../../../src/pages/showcase/index.tsx" /* webpackChunkName: "component---src-pages-showcase-index-tsx" */)
}

